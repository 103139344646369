<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' title="所在位置" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="1200px" :before-close="closeMypup">
			<div>
				<div style="margin-bottom: 12px;">
					经纬度：
					<el-input v-model="latObj.lng" style='width: 47%;' placeholder="经度"  @change='creatOrDragMarker' oninput="value=value.replace(/[^\d^\.]+/g,'')"/>
					<el-input v-model="latObj.lat" style='width: 47%;' placeholder="纬度"  @change='creatOrDragMarker' oninput="value=value.replace(/[^\d^\.]+/g,'')"/>
				</div>
				<div class="myMap" id="bMap1"></div>
				<div class="btmBtn">
					<el-button type="primary" @click='saveMaker' :disabled='!listenMarker'>保存</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			lnglats: {
				type: Object,
				default: ()=>{
					return {
						lng:'',
						lat:'',
					}
				},
			},
		},
		components: {},
		data() {
			return {
				mp:null,
				dfMapSize:16,
			 	defaultPoint: {
			        lng: 114.066182,
			        lat: 22.549351,
		      	},
		      	latObj:{
		      		lat:'',
		      		lng:'',
		      	},
		      	listenMarker:null, //监听状态的marker
			}
		},

		computed: {},
		watch: {
			lnglats: {
				deep: true, // 深度监听
				handler(val, oval) {
				}
			},

		},
		created() {},
		mounted() {
			this.$nextTick(()=>{
				console.log('sssssssthat.listenMarker')
				this.initBaiduMap();
			})
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			initBaiduMap() {
		      	//初始化百度地图
		      	let that = this
		      	let bMap = new BMap.Map("bMap1", {
		        	enableMapClick: false,
		        	minZoom: 8,
		        	maxZoom: 19,
		      	})
		      	let point = new BMap.Point(this.defaultPoint.lng, this.defaultPoint.lat) //创建点坐标
		      	bMap.centerAndZoom(point, this.dfMapSize) //初始化地图，设置中心点坐标和地图级别
		      	bMap.enableScrollWheelZoom(true)
		      	//获取用户当前所在城市
				var myCity = new BMap.LocalCity();
				myCity.get(function (result){
					let point=result.center;
				  	let city = new BMap.Point(point.lng, point.lat); //创建点坐标
				  	if(that.latObj.lat) return;
				  	bMap.centerAndZoom(city, 12); //初始化地图，设置中心点坐标和地图级别
				});
				//获取用户当前位置
				if (navigator.geolocation) {
				    navigator.geolocation.getCurrentPosition(function(position) {
				        let lat = position.coords.latitude;
				        let lng = position.coords.longitude;
				        console.log('lat',lat,lng)
				  		let city = new BMap.Point(lng,lat); //创建点坐标
				  		bMap.centerAndZoom(city, 12); //初始化地图，设置中心点坐标和地图级别
				    });
				}
		      	this.mp = bMap;
		      	this.mp.addEventListener("click", function(e){
		      		that.latObj=e.point;
		      		that.creatOrDragMarker();
		      	});
		      	if(this.lnglats.lng&&this.lnglats.lat){
		      		this.latObj=JSON.parse(JSON.stringify(this.lnglats));
					this.creatOrDragMarker();
					let p1 = new BMap.Point(this.lnglats.lng, this.lnglats.lat);
					this.mp.panTo(p1,18)
		      	}
		      	
		    },
		    inplatChange(){
		    	
		    	
		    	
		    },
		    creatOrDragMarker(){
		    	let data=JSON.parse(JSON.stringify(this.latObj));
		    	if(data.lng&&data.lat){
		    		var point = new BMap.Point(data.lng, data.lat);
		    		if(this.listenMarker){
		    			this.listenMarker.setPosition(point);
		    			this.mp.panTo(point,18)
		    		}else{
		    			let marker = new BMap.Marker(point, {
						 	enableDragging: true
						});
						let that=this;
					 	marker.addEventListener('dragend', function (e) {
					 		
					 		console.log('-----------dragend',e.point)
					 		that.latObj={...e.point};
					 		
					 	})
						this.listenMarker=marker;
						this.mp.panTo(point,18)
						this.mp.addOverlay(this.listenMarker);
		    		}
		    		
		    		
		    	}
		    	
		    	
		    	
		    	
		    },
			saveMaker(){
				if(this.listenMarker){
					console.log('listenMarker',this.listenMarker.point)
					this.$emit('mkSave',this.listenMarker.point);
				}
				
				
			},
			closeMypup(){
				this.$emit('close');
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
	.btmBtn {
		margin-top: 12px;
		text-align: right;
	}
	
	.myMap {
		height: 600px;
	}
</style>